import { Token } from "@mui/icons-material";
import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log(apiUrl, "api")
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const login = async (inputs) => {
   
    try{
    const res = await axios.post(
       apiUrl+"/users/user/userLogin",
      { userEmailOrMobileNumber: inputs.username, userPassword: inputs.password },
    );
    console.log(res)

    if (res.data) {
      setCurrentUser({
        id: res.data.userId,
        username: res.data.userFirstName,
        email: res.data.userEmail,
        name: res.data.userFirstName,
        coverPic: res.data.userCoverImage,
        profilePic: res.data.userProfileImage,
        districtId: res.data.districtId,
        city: res.data.address,
        website: null,
        token: res.data.token,
        isAdmin : res.data.isAdmin
      });

      localStorage.setItem(
        "user",
        JSON.stringify({
          id: res.data.userId,
          username: res.data.userFirstName,
          email: res.data.userEmail,
          name: res.data.userFirstName,
          coverPic: res.data.userCoverImage,
          profilePic: res.data.userProfileImage,
          districtId: res.data.districtId,
          city: res.data.address,
          website: null,
          token: res.data.token,
          isAdmin : res.data.isAdmin
        })
      );
    }
  }
    catch(e){
      if(e && e.response.data.message){
        throw new Error(e.response.data.message)
      }
      else{
        throw new Error("Something went wrong")
      }
      
    }

   

  };
  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem("user");
  };



  return (
    <AuthContext.Provider value={{ currentUser, login, logout,setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
