import "./adminNavbar.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link } from "react-router-dom";
import { useContext, useState, useEffect, useCallback } from "react";
import { DarkModeContext } from "../../../context/darkModeContext";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
// import FriendItem from "../../pages/friendsListing/FriendItem";
import { debounce } from 'lodash';
import { Modal } from "antd";
import { makeRequest } from "../../../axios";
import logo_symbol from "../../../assets/logoCRI.png";
import { yellow } from "@mui/material/colors";

const AdminNavbar = () => {
  const { toggle, darkMode } = useContext(DarkModeContext);
  const { currentUser, logout } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchResultVisible, setIsSearchResultVisible] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Debounced search function
  const fetchSearchResults = useCallback(debounce(async (query) => {
    try {
      const response = await makeRequest.get(`/ssakti/users/user/searchUser/${query}`);
      setSearchResults(response.data.searchedData);
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  }, 300), []);

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
    if (value.length > 0) {
      fetchSearchResults(value);
    } else {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchInput.length === 0) {
      setSearchResults([]);
    }
  }, [searchInput]);

  return (
    <div className={`adminNavbar ${darkMode ? 'dark' : 'light'}`}>
      <div className="left">
        <Link to="/" style={{ textDecoration: "none" }}>
        <img src={logo_symbol} style={{ width:"50px",height:"50px" }} alt="Logo" />
        </Link>
        <Link to='/'>
        <HomeOutlinedIcon
            sx={{ color: darkMode == true ? "#fff" : '#000' }}
            aria-label="Home"
          />
        </Link>
        {darkMode ? (
          <WbSunnyOutlinedIcon sx={{color:yellow[700]}} onClick={toggle} />
        ) : (
          <DarkModeOutlinedIcon onClick={toggle} />
        )}
        {/* <div className="search">
          <SearchOutlinedIcon />
          <input
            type="text"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearchInputChange}
            onFocus={() => setIsSearchResultVisible(true)}
            onBlur={() => setTimeout(() => setIsSearchResultVisible(false), 100)}  // Use timeout to avoid quick blurring issues
          />
          {isSearchResultVisible && (
            <div className="searchResultsContainer">
              {searchResults.map((result) => (
                <FriendItem key={result.id} friend={result} />
              ))}
            </div>
          )}
        </div> */}
      </div>
      <div className="right">
        <button onClick={() => setIsModalVisible(true)}>Logout</button>
        <div className="user">
          <Link to={`/profile/${currentUser.id}`}>
            <img
              src={currentUser.profilePic}
              alt={currentUser.name}
            />
            <span>{currentUser.name}</span>
          </Link>
        </div>
      </div>
      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleLogout}
        onCancel={handleCancel}
        okButtonProps={{ style: { backgroundColor: '#ec4040', color: 'white' } }} 
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </div>
  );
};

export default AdminNavbar;
